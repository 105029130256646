import { React } from "react";
import "../assets/css/vistaFormulario.css";
import { Container } from "@mui/material";
import { HeaderVista } from "../components/organism";
import { Volver } from "../components/molecule";

const SucesoNewScreen = () => {
  return (
    <Container maxWidth="lg">
      <HeaderVista start={<Volver label="Volver" />} titulo={"Crear suceso"} end={<></>} />
      <br />
    </Container>
  );
};

export default SucesoNewScreen;
