import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import * as React from "react";
import "../../assets/css/MUIPickers.css";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
});

export const DatePickerCustom = ({ label, value, handleChange, name, disabled }) => {
  const classes = useStyles();

  const handleInput = (newValue) => {
    if (newValue) handleChange(dayjs(newValue).format("DD-MM-YYYY"), name);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateCalendar", "DateCalendar"]}>
        <DatePicker
          className={classes.fullWidth}
          name={name}
          label={label}
          disabled={disabled}
          value={dayjs(value, "DD-MM-YYYY")}
          onChange={(newValue) => handleInput(newValue)}
          slotProps={{
            textField: {
              helperText: "DD-MM-YYYY",
            },
          }}
          views={["year", "month", "day"]}
          format="DD-MM-YYYY"
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
