import React, { useRef } from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";

import "../../assets/css/ButtonIcon.css";

export default function ButtonIcon(props) {
  const inputRef = useRef(null);

  const handleClick = () => {
    if (props?.handleFileChange) {
      inputRef.current.click();
    } else {
      props?.handleClick();
    }
  };

  if (props.noLink) {
    return (
      <Button
        onClick={handleClick}
        className={"ButtonIcon " + (props.children && props.startIcon ? " hasChildrenIconStart " : "") + (props.children && props.endIcon ? " hasChildrenIconEnd " : "")}
        type={props.type}
        aria-label={props.arialLabel}
        variant={props.variant ?? "outlined"}
        size={props.size ?? "small"}
        color={props.color ?? "primary"}
        startIcon={props.startIcon ? props.startIcon : ""}
        endIcon={props.endIcon ? props.endIcon : ""}
        style={(props.style, props.styleButton && props.styleButton)}
        disabled={props.disabled}
        name={props.name}
      >
        <span style={{ fontSize: "0.68rem" }}>{props.children}</span>
        {props.handleFileChange && <input type="file" accept="image/*" multiple ref={inputRef} style={{ display: "none" }} onChange={props.handleFileChange} />}
      </Button>
    );
  } else {
    return (
      <Link to={props.to && !props.disabled ? props.to : "#"} style={props.style}>
        <Button
          className={"ButtonIcon " + (props.children && props.startIcon ? " hasChildrenIconStart " : "") + (props.children && props.endIcon ? " hasChildrenIconEnd " : "")}
          variant={props.variant ?? "outlined"}
          size={props.size ?? "small"}
          color={props.color ?? "primary"}
          aria-label={props.arialLabel}
          startIcon={props.startIcon ? props.startIcon : ""}
          endIcon={props.endIcon ? props.endIcon : ""}
          style={props.styleButton && props.styleButton}
          disabled={props.disabled}
        >
          <span>{props.children}</span>
        </Button>
      </Link>
    );
  }
}
