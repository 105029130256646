import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStatusSidebar } from "../store/slices/sidebar";
import { PUBLIC_ROUTES } from "./name-path.routes";
import { Sidebar } from "../components/organism/Navbar/Sidebar";

export const Authenticated = () => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    sessionStorage.clear();
  }
  const location = useLocation();
  const statusSidebar = useSelector(getStatusSidebar);
  return token ? (
    <>
      <Sidebar />
      <div id="routesContainer" className={!statusSidebar ? "extendido" : ""}>
        <div id="contenedor">
          <Outlet style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "15px" }} />
        </div>
      </div>
    </>
  ) : (
    <Navigate to={PUBLIC_ROUTES.Login} state={{ from: location }} replace />
  );
};
