import { tiendasApi } from "../api";

export const TipoIncidenciaApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getTiposIncidente: builder.mutation({
      query: (headersTemp) => {
        const { page, limit } = headersTemp;
        return {
          url: `/tipodeincidencias?page=${page}&limit=${limit}`,
          method: "GET",
        };
      },
    }),
    createTipoIncidente: builder.mutation({
      query: (body) => ({
        url: "/tipodeincidencias",
        method: "POST",
        body: body,
      }),
    }),
    updateTipoIncidente: builder.mutation({
      query(element) {
        const { id, datos } = element;
        return {
          url: `/tipodeincidencias/${id}`,
          method: "PUT",
          body: datos,
        };
      },
    }),
    deleteTipoIncidente: builder.mutation({
      query: (id) => ({
        url: `/tipodeincidencias/${id}`,
        method: "DELETE",
      }),
    }),
    getIncidencia: builder.mutation({
      query: (id) => ({
        url: "/tipodeincidencias/" + id,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetTiposIncidenteMutation, useGetIncidenciaMutation, useCreateTipoIncidenteMutation, useDeleteTipoIncidenteMutation, useUpdateTipoIncidenteMutation } = TipoIncidenciaApiSlice;
