import { lazy } from "react";
import { PRIVATE_ROUTES } from "./name-path.routes";
import SucesosScreen from "../pages/SucesoScreen";
import SucesoNewScreen from "../pages/SucesoNewScreen";
import SucesoFichaScreen from "../pages/SucesoFichaScreen";
import SucesoEditScreen from "../pages/SucesoEditScreen";
import MiPerfilScreen from "../pages/MiPerfilScreen";

export const ROUTES_PRIVATES = [
  //Sucesos
  { path: PRIVATE_ROUTES.LIST_SUCESOS, component: SucesosScreen },
  { path: PRIVATE_ROUTES.NEW_SUCESOS, component: SucesoNewScreen },
  { path: PRIVATE_ROUTES.FICHA_SUCESOS, component: SucesoFichaScreen },
  { path: PRIVATE_ROUTES.EDIT_SUCESOS, component: SucesoEditScreen },
  //Mi perfil
  { path: PRIVATE_ROUTES.SELF, component: MiPerfilScreen },
];
