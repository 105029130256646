import FolderIcon from "@mui/icons-material/Folder";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import { PRIVATE_ROUTES } from "../routes/name-path.routes";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";

export const SidebarData = [
  {
    id: 1,
    label: "Sucesos",
    icono: (
      <NaturePeopleIcon
        sx={{
          color: "black",
        }}
      />
    ),
    path: PRIVATE_ROUTES.HOME,
    desplegable: false,
  } /*,
  {
    id: 2,
    label: "Usuarios",
    icono: (
      <PersonIcon
        sx={{
          color: "black",
        }}
      />
    ),
    path: PRIVATE_ROUTES.LIST_USERS,
    desplegable: false,
  },
  {
    id: 3,
    label: "Estados",
    icono: (
      <FolderIcon
        sx={{
          color: "black",
        }}
      />
    ),
    path: PRIVATE_ROUTES.LIST_STATES,
    desplegable: false,
  },
  {
    id: 3,
    label: "Sucesos",
    icono: (
      <NaturePeopleIcon
        sx={{
          color: "black",
        }}
      />
    ),
    path: PRIVATE_ROUTES.LIST_SUCESOS,
    desplegable: false,
  },*/,
];
