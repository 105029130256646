import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchIcon from "@mui/icons-material/Search";
import { ButtonIcon } from "../atoms";
import { PRIVATE_ROUTES } from "../../routes/name-path.routes";
import { convertDateUtc } from "../../utils/formatDate";

export const TableRowSuceso = ({ row }) => (
  <tr style={{ height: "10%" }}>
    <td style={{ display: "flex", alignItems: "center" }}>
      {row?.activo ? <FiberManualRecordIcon style={{ color: "green" }} /> : <FiberManualRecordIcon style={{ color: "#ff1c1c" }} />}
      <p>{row?.identificador}</p>
    </td>
    <td align="">
      <p>{convertDateUtc(row?.fechahora)}</p>
    </td>
    <td align="">
      <p>{row?.estado}</p>
    </td>
    <td align="">
      <p>{row?.incidencia}</p>
    </td>
    <td align="">
      <p>{row?.tipoCorteDeCalle}</p>
    </td>
    <td align="">
      <p>{convertDateUtc(row?.fecha_ultmodif)}</p>
    </td>
    <td align="">
      <ButtonIcon arialLabel="detailsUser" startIcon={<SearchIcon />} to={`${PRIVATE_ROUTES.FICHA_SUCESOS}?id=${row._id}`} />
    </td>
  </tr>
);
