import { tiendasApi } from "../api";

export const TipoCortesApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getTipoCortes: builder.mutation({
      query: (headersTemp) => {
        const { page, limit } = headersTemp;
        return {
          url: `/tipocortedecalle?page=${page}&limit=${limit}`,
          method: "GET",
        };
      },
    }),
    createTipoCorteDeCalle: builder.mutation({
      query: (body) => ({
        url: "/tipocortedecalle",
        method: "POST",
        body: body,
      }),
    }),
    updateTipoCorteDeCalle: builder.mutation({
      query(element) {
        const { id, datos } = element;
        return {
          url: `/tipocortedecalle/${id}`,
          method: "PUT",
          body: datos,
        };
      },
    }),
    deleteTipoCorteDeCalle: builder.mutation({
      query: (id) => ({
        url: `/tipocortedecalle/${id}`,
        method: "DELETE",
      }),
    }),
    getTipoCorteDeCalle: builder.mutation({
      query: (id) => ({
        url: "/tipocortedecalle/" + id,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetTipoCortesMutation, useCreateTipoCorteDeCalleMutation, useDeleteTipoCorteDeCalleMutation, useGetTipoCorteDeCalleMutation, useUpdateTipoCorteDeCalleMutation } = TipoCortesApiSlice;
