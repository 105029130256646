/* eslint-disable no-unused-vars */
import { Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetSelfMutation } from "../../../store/api/endpoints/authApiSlice";
import { alone, aloneComuna, getSucesoComuna } from "../../../store/slices/sucesos";
import { SmallInput } from "../../molecule/SmallInput";
import SelectSmall from "../../molecule/SmallSelect/SmallSelect";
import ListImages from "../ListImages/ListImages";
import { style } from "./styles";

const ListaDeAtributosSuceso = () => {
  const suceso = useSelector(getSucesoComuna, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [getSelf, responseSelf] = useGetSelfMutation();

  const handleCancel = () => {
    sessionStorage.removeItem("creandoSuceso");
    let redirect = PRIVATE_ROUTES.LIST_SUCESOS;
    if (id) {
      redirect = `${PRIVATE_ROUTES.FICHA_SUCESOS}?id=${id}`;
    }
    navigate(redirect);
  };

  const onChange = (value, key) => {
    console.log("Cambio", key, value);
    dispatch(aloneComuna({ [key]: value }));
  };

  useEffect(() => {
    if (!id) {
      getSelf();
    }
  }, []);

  useEffect(() => {
    if (responseSelf.isSuccess && responseSelf.data._id) {
      dispatch(
        alone({
          carga_administrador: responseSelf.data._id,
        })
      );
    }
  }, [responseSelf.isSuccess]);

  const handleSubmit = () => {};

  return (
    <List sx={style} aria-label="mailbox folders">
      <ListItem>
        <ListItemText sx={{ display: "flex", justifyContent: "center", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700, fontSize: "1.2rem" }}>Informe Comuna</Typography>} />
      </ListItem>
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Calle:</Typography>} />
        <SelectSmall
          lista={[
            { key: 1, label: "Opcion 1" },
            { key: 2, label: "Opcion 2" },
            { key: 3, label: "Opcion 3" },
          ]}
          value={suceso?.calle}
          name="calle"
          onChange={onChange}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Chapa:</Typography>} />
        <SmallInput restricciones={["soloNumeros"]} value={suceso?.chapa} onChange={onChange} name="chapa" />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Referencia:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.referencia} onChange={onChange} name="referencia" />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Geolocalizacion (Latitud, Longitud):</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.geolocalizacion} onChange={onChange} name="geolocalizacion" />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Especie Árbol:</Typography>} />
        <SelectSmall
          lista={[
            { key: 1, label: "Opcion 1" },
            { key: 2, label: "Opcion 2" },
            { key: 3, label: "Opcion 3" },
          ]}
          value={suceso?.especie_arbol}
          name="especie_arbol"
          onChange={onChange}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>DAP (cm):</Typography>} />
        <SmallInput restricciones={["soloNumeros"]} value={suceso?.dap} onChange={onChange} name="dap" />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Altura(m):</Typography>} />
        <SmallInput restricciones={["soloNumeros"]} value={suceso?.altura} onChange={onChange} name="altura" />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Daño:</Typography>} />
        <SelectSmall
          lista={[
            { key: 1, label: "Opcion 1" },
            { key: 2, label: "Opcion 2" },
            { key: 3, label: "Opcion 3" },
          ]}
          value={suceso?.danio}
          name="danio"
          onChange={onChange}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Incidencia:</Typography>} />
        <SelectSmall
          lista={[
            { key: 1, label: "Opcion 1" },
            { key: 2, label: "Opcion 2" },
            { key: 3, label: "Opcion 3" },
          ]}
          value={suceso?.incidencia}
          name="incidencia"
          onChange={onChange}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Estado:</Typography>} />
        <SelectSmall
          lista={[
            { key: 1, label: "Opcion 1" },
            { key: 2, label: "Opcion 2" },
            { key: 3, label: "Opcion 3" },
          ]}
          value={suceso?.estado}
          name="estado"
          onChange={onChange}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Corte de calle:</Typography>} />
        <SelectSmall
          lista={[
            { key: 1, label: "Opcion 1" },
            { key: 2, label: "Opcion 2" },
            { key: 3, label: "Opcion 3" },
          ]}
          value={suceso?.corte_calle}
          name="corte_calle"
          onChange={onChange}
        />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "71px" }}>
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Observaciones:</Typography>} />
        <SmallInput restricciones={["alfaNumerico"]} value={suceso?.observaciones} onChange={onChange} name="observaciones" />
      </ListItem>
      <Divider component="li" />
      <ListItem sx={{ height: "100%", minHeight: "71px" }}>
        {console.log("suceso", suceso)}
        <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Fotos de comuna:</Typography>} />
        <ListImages imagenes={suceso?.fotos_comuna} ruta={`${suceso}?.fotos_comuna`} handleChange={() => {}} editable={true} />
      </ListItem>
    </List>

    /* {editting && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonIcon type="button" variant="contained" color="error" noLink handleClick={handleCancel} startIcon={<CancelOutlined />}>
              Cancelar
            </ButtonIcon>
            <ButtonIcon variant="contained" color="success" noLink startIcon={<CheckIcon />} handleClick={handleSubmit} styleButton={{ marginLeft: "10px" }}>
              Guardar
            </ButtonIcon>
          </Grid>
        </Grid>
      )} */
  );
};

export default ListaDeAtributosSuceso;
