import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ButtonIcon } from "../components/atoms";
import { Loading, Volver } from "../components/molecule";
import { HeaderVista, ListadoInformeEmpresa, ListadoInformeGeneral } from "../components/organism";
import ListaDeAtributosSuceso from "../components/organism/suceso/FormSuceso";
import { PRIVATE_ROUTES } from "../routes/name-path.routes";
import { useGetSucesoMutation } from "../store/api/endpoints/sucesosApiSlice";
import { alone } from "../store/slices/sucesos";

const SucesoEditScreen = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [getSuceso, responseSuceso] = useGetSucesoMutation();

  const id = searchParams.get("id");

  useEffect(() => {
    if (responseSuceso.isSuccess) {
      let sucesoFormatted = { ...responseSuceso?.data?.data };
      let splitFecha = sucesoFormatted?.fechahora.split("T");
      sucesoFormatted.fecha = splitFecha[0];
      sucesoFormatted.hora = splitFecha[1];
      dispatch(alone(sucesoFormatted));
    }
  }, [responseSuceso.isSuccess]);

  useEffect(() => {
    getSuceso(id);
  }, []);

  if (responseSuceso.isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" />}
        titulo={"Editando el suceso"}
        end={<ButtonIcon variant="outlined" size="small" color="primary" startIcon={<EditIcon />} to={`${PRIVATE_ROUTES.EDIT_SUCESOS}?id=${id}`} children="Editar" />}
      />
      <ListadoInformeGeneral />
      <ListaDeAtributosSuceso />
      <ListadoInformeEmpresa />
      <br />
    </Container>
  );
};

export default SucesoEditScreen;
