import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import "../../assets/css/MUIPickers.css";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
});

export const TimePickerCustom = ({ label, value = "00:05:00", handleChange, name, disabled }) => {
  const classes = useStyles();
  const handleInput = (newValue) => {
    if (newValue) handleChange(dayjs(newValue).format("HH:mm:ss"), name);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimePicker", "TimePicker"]}>
        <TimePicker Format="LongTime" FormatString="hh:mmtt" disabled={disabled} className={classes.fullWidth} name={name} label={label} value={dayjs(value, "HH:mm:ss")} onChange={handleInput} format="HH:mm:ss" />
      </DemoContainer>
    </LocalizationProvider>
  );
};
