export const style = {
  p: 0,
  width: "100%",
  margin: "30px 0px",
  borderRadius: 2,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  height: "100%",
};
