import { createSlice } from "@reduxjs/toolkit";

const initAlone = { nombre: "", codigo: "", activo: false };
const paginationInfo = {
  hasNextPage: null,
  hasPrevPage: null,
  limit: 50,
  nextPage: 2,
  page: 1,
  pagingCounter: 1,
  prevPage: null,
  totalDocs: 218,
  totalPages: 5,
};
export const tipoIncidenciaSlice = createSlice({
  name: "tipoIncidencia",
  initialState: {
    list: [],
    alone: initAlone,
    filtersCleared: false,
    pagination: paginationInfo,
  },
  reducers: {
    listIncidentes: (state, action) => {
      state.list = action.payload;
    },
    alone: (state, action) => {
      state.alone = {
        ...state.alone,
        ...action.payload,
      };
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    setfilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clean: (state) => {
      state.alone = initAlone;
      state.filtersCleared = true;
      state.pagination = paginationInfo;
    },
  },
});

export const { clean, listIncidentes, alone, setPagination, setfilters } = tipoIncidenciaSlice.actions;

export const getListTipoIncidente = (state) => state.tipoIncidencia.list;
export const getPagination = (state) => state.tipoIncidencia.pagination;
export const getFiltersCleared = (state) => state.tipoIncidencia.filtersCleared;
export const gettipoIncidente = (state) => state.tipoIncidencia.alone;
