import ArticleIcon from "@mui/icons-material/Article";
import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { LIST_COMUNAS } from "../../../constants/filterSucesos";
import { getListEstados } from "../../../store/slices/estado";
import { ButtonIcon, Input } from "../../atoms";
import { DatePickerCustom, SelectMultiple, TimePickerCustom } from "../../molecule";
import KeepMountedModal from "../../molecule/Modal/Modal";
import { getListTipoIncidente } from "../../../store/slices/tipoIncidentes";
import { getListTipoCortes } from "../../../store/slices/tipoCortes";
import { getFilters, setfilters } from "../../../store/slices/sucesos";

const Filter = ({ setModalFilter, handleSave }) => {
  const dispatch = useDispatch();
  const filters = useSelector(getFilters, shallowEqual);
  const incidencia = useSelector(getListTipoIncidente, shallowEqual);
  const estados = useSelector(getListEstados, shallowEqual);
  const cortes = useSelector(getListTipoCortes, shallowEqual);
  const [error, seterror] = useState(false);
  const [localFilters, setlocalFilters] = useState();
  const changeValue = useCallback((value, name) => {
    setlocalFilters((prev) => ({ ...prev, [name]: value }));
  }, []);
  useEffect(() => {
    dispatch(setfilters(localFilters));
  }, [localFilters]);

  return (
    <KeepMountedModal setModalFilter={setModalFilter}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h2 style={{ fontSize: "1.2rem", fontWeight: 300, textAlign: "center", margin: "2px 0px" }}>Filtros</h2>
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            label="Buscar por ID / Dirección / Teléfono vecino"
            icon={<ArticleIcon />}
            value={filters.input}
            name="input"
            onChange={changeValue}
            type="text"
            restricciones={["alfanumerico"]}
            required={true}
            disabled={false}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerCustom value={filters?.fechaDesde} error={error} handleChange={changeValue} name={"fechaDesde"} label="Fecha Llamado Desde" disabled={false} seterror={seterror} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TimePickerCustom value={filters?.horaDesde} error={error} handleChange={changeValue} name={"horaDesde"} label="Hora Llamado Desde" disabled={false} seterror={seterror} />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerCustom value={filters?.fechaHasta} error={error} handleChange={changeValue} name={"fechaHasta"} label="Fecha Llamado Hasta" disabled={false} seterror={seterror} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TimePickerCustom value={filters?.horaHasta} error={error} handleChange={changeValue} name={"horaHasta"} label="Hora Llamado Hasta" disabled={false} seterror={seterror} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectMultiple label="Estado" disabled={false} defaultValue="" options={estados} type="text" onChange={changeValue} elegidos={filters.estado} name={"estado"} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectMultiple label="Incidencia" disabled={false} defaultValue="" options={incidencia} type="text" onChange={changeValue} elegidos={filters.incidencia} name={"incidencia"} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectMultiple label="Corte de Calle" disabled={false} defaultValue="" options={cortes} type="text" onChange={changeValue} elegidos={filters.tipoCortesDeCalles} name={"tipoCortesDeCalles"} />
        </Grid>
        <Grid item xs={12}>
          <ButtonIcon variant="contained" color="primary" noLink handleClick={handleSave} styleButton={{ marginLeft: "10px", color: "white" }}>
            Buscar
          </ButtonIcon>
        </Grid>
      </Grid>
    </KeepMountedModal>
  );
};

export default React.memo(Filter);
