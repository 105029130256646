import { tiendasApi } from "../api";

export const stateApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getStates: builder.mutation({
      query: (headersTemp) => {
        const { page, limit } = headersTemp;
        return {
          url: `/estado?page=${page}&limit=${limit}`,
          method: "GET",
        };
      },
    }),
    getState: builder.mutation({
      query: (id) => ({
        url: "/estado/" + id,
        method: "GET",
      }),
    }),
    createState: builder.mutation({
      query: (body) => ({
        url: "/estado",
        method: "POST",
        body: body,
      }),
    }),
    updateState: builder.mutation({
      query(element) {
        const { id, datos } = element;
        return {
          url: `/estado/${id}`,
          method: "PUT",
          body: datos,
        };
      },
    }),
    deleteState: builder.mutation({
      query: (id) => ({
        url: `/estado/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useGetStateMutation, useGetStatesMutation, useCreateStateMutation, useUpdateStateMutation, useDeleteStateMutation } = stateApiSlice;
