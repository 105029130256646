import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeaderVista } from "../components/organism";
import { Input } from "../components/atoms";
import { useGetSelfMutation } from "../store/api/endpoints/authApiSlice";
import { Loading } from "../components/molecule";

const MiPerfilScreen = () => {
  const [getSelf, responseSelf] = useGetSelfMutation();
  const [self, setself] = useState({
    activo: false,
    apellido: "",
    email: "",
    nombre: "",
    telefono: "",
  });

  useEffect(() => {
    getSelf(sessionStorage.getItem("token"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseSelf.isSuccess) {
      setself(responseSelf?.data);
    }
  }, [responseSelf.isSuccess]);

  if (responseSelf.isLoading) return <Loading />;
  return (
    <Container component="main" maxWidth="lg">
      <HeaderVista start={<></>} titulo={"Mi perfil"} end={<></>} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Input label="Nombre Completo" value={self?.nombre + " " + self?.apellido} type="text" restricciones={["alfanumerico"]} disabled />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input label="Telefono" value={`+${self?.telefono}`} type="tel" restricciones={["soloNumeros"]} disabled />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input label="Email" value={self?.email} type="text" restricciones={["alfanumerico"]} disabled />
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default MiPerfilScreen;
