import React from "react";
import Container from "@mui/material/Container";
import { FormLogin } from "../components/organism";

const LoginScreen = () => {
  return (
    <Container component="main" maxWidth="xs">
      <FormLogin />
      <br />
    </Container>
  );
};

export default LoginScreen;
