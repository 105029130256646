import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Input from "./Input";
import ButtonIcon from "./ButtonIcon";
import { Grid } from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";

export const PasswordInput = ({ label, required, password, handleChange, name, disabled }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (name, value) => {
    handleChange(value, name);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <div className="password-input-container" style={{ display: "flex", alignItems: "flex-end", flexDirection: "row-reverse" }}>
        <ButtonIcon
          size={"big"}
          variant={"contained"}
          aria-label="Visibility password"
          noLink
          disabled={disabled}
          startIcon={showPassword ? <VisibilityIcon aria-label="Show password" /> : <VisibilityOffIcon aria-label="hidde password" />}
          handleClick={handleTogglePassword}
          styleButton={{ marginTop: "15px", alignSelf: "center", height: "55.972px" }}
        />
        <Input
          type={showPassword ? "text" : "password"}
          id="password"
          name={name}
          label={label}
          value={password}
          required={required}
          disabled={disabled}
          placeholder="Contraseña"
          restricciones={["alfanumerico"]}
          icon={<PasswordIcon />}
          onChange={(value) => handlePasswordChange(name, value)}
        />
      </div>
    </Grid>
  );
};
