import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    nombre: null,
    permisos: null,
    user: {
      email: "",
      password: "",
    },
    self: {},
  },
  reducers: {
    setCredentials: (state, action) => {
      state.token = action.payload.token;
      sessionStorage.setItem("token", action.payload.token);
    },
    updateUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload.user,
      };
    },
    self: (state, action) => {
      state.self = action.payload.self;
    },
    logOut: (state, action) => {
      sessionStorage.removeItem("token");
    },
  },
});

export const { setCredentials, logOut, updateUser, self } = authSlice.actions;

export const getUsuarioNombre = (state) => state.auth.nombre;
export const getUsuarioToken = () => sessionStorage.getItem("token");
export const getUsuarioPermisos = (state) => state.auth.permisos;
export const getUserLogin = (state) => state.user;
