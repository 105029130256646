export const columnsState = [
  { field: "id", headerName: "Id", width: 40 },
  { field: "nombre", headerName: "Nombre", width: 40 },
  { field: "ver", headerName: "Ver", width: 20 },
];

export const columnsSucesos = [
  { field: "identificador", headerName: "Identificador", align: "left" },
  { field: "fechahora", headerName: "Fecha Llamado", width: 10, align: "center" },
  { field: "estado", headerName: "Estado", align: "center" },
  { field: "incidencia", headerName: "Incidencia", align: "left" },
  { field: "tipoCorteDeCalle", headerName: "Corte de Calle", width: 60, align: "left" },
  { field: "fecha_ultmodif", headerName: "Ult. Modif", align: "center" },
  { field: "ver", headerName: "Ver", align: "left" },
];
