import * as React from "react";
import PropTypes from "prop-types";
import { Container } from "@mui/material";
import "../../assets/css/table.css";
import Pagination from "./Pagination";

const DataTable = React.memo(({ rows, columns, RowComponent, pagination, setPagination, responseAllClientes, label }) => {
  const data = React.useMemo(() => rows, [rows]);

  const renderTableHeader = () => (
    <tr style={{ height: "10%" }}>
      {columns.map((column, index) => (
        <th key={index} align="left">
          {column.headerName}
        </th>
      ))}
    </tr>
  );

  const renderTableBody = () => {
    if (data?.length > 0) {
      return data?.map((row) => <RowComponent key={row._id} row={row} />);
    }

    return (
      <tr>
        <td colSpan="12">No se encontraron registros</td>
      </tr>
    );
  };

  return (
    <Container maxWidth="lg">
      <div className="tableContainer">
        <table className="formato1" cellSpacing="0" cellPadding="0">
          <thead>{renderTableHeader()}</thead>
          <tbody>{renderTableBody()}</tbody>
        </table>
      </div>
      <Pagination setPagination={setPagination} label={label} pagination={pagination} nextPage={responseAllClientes?.nextUrl ? responseAllClientes?.nextUrl : false} value={pagination.value || 4} />
    </Container>
  );
});

DataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  RowComponent: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  responseAllClientes: PropTypes.object,
  label: PropTypes.string,
};

export default DataTable;
