import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FolderIcon from "@mui/icons-material/Folder";
import { InputAdornment } from "@mui/material";
import "../../../assets/css/Sidebar.css";
import "./style.css";
import { changeStatusSidebar } from "../../../store/slices/sidebar";
import { useDispatch } from "react-redux";
const SubMenu = ({ item, rolActual }) => {
  const [subnav, setSubnav] = useState(false);
  const dispatch = useDispatch();
  const [isDesplegable, setisDesplegable] = useState(false);

  useEffect(() => {
    setisDesplegable(item.desplegable);
  }, []);

  const showSubnav = () => {
    setSubnav(!subnav);
  };

  const hideSidebar = () => {
    dispatch(changeStatusSidebar(false));
    setSubnav(false);
  };
  return (
    <div
      style={{
        maxHeight: subnav ? "" : "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "black",
        justifyContent: "center",
      }}
    >
      {isDesplegable && (
        <>
          <Link to={item.to} onClick={showSubnav} className="SidebarLink">
            <div className="icono">
              <InputAdornment position="start">{item.icono}</InputAdornment>
              <p style={{ width: "70%" }}>{item.label}</p>
              <div style={{ position: "end" }}>{item.items && subnav ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
            </div>
          </Link>
          <div id="listItems">
            {subnav &&
              item.items.map((subitem, index) => (
                <Link to={subitem.to} onClick={hideSidebar} key={index} className="DropdownLink">
                  <FolderIcon style={{ color: "grey" }} />
                  <label>{subitem.label}</label>
                </Link>
              ))}
          </div>
        </>
      )}

      {!isDesplegable && (
        <Link to={item.path} onClick={hideSidebar} className="SidebarLink">
          <div className="icono">
            <InputAdornment position="start">{item.icono}</InputAdornment>
            <p>{item.label}</p>
          </div>
        </Link>
      )}
    </div>
  );
};

export default SubMenu;
