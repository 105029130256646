import * as React from "react";
import TablePagination from "@mui/material/TablePagination";
import "../../assets/css/Pagination.css";
import { Container, Grid } from "@mui/material";

export default function Pagination({ setPagination, pagination, label }) {
  const handleChangePage = (e, newPage) => {
    setPagination((prev) => ({
      ...prev,
      page: parseInt(newPage),
    }));
  };
  const handleChangeRowsPerPage = (event) => {
    setPagination((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  };

  return (
    <Container component={"main"} maxWidth="sm">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TablePagination
            component="div"
            count={pagination?.total ?? 10}
            rowsPerPageOptions={[4, 8, 16]}
            page={pagination?.page}
            style={{ display: "flex", justifyContent: "center" }}
            onPageChange={handleChangePage}
            rowsPerPage={pagination.cantRows ?? 12}
            labelRowsPerPage={label}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
