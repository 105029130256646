import { createSlice } from "@reduxjs/toolkit";
import { Global } from "../../../constants/global";
import moment from "moment";

const initAlone = {
  agencia: "",
  domicilio: "",
  tipo: "",
  subtipo: "",
  telefono_vecino: "",
  relato: "",
  origen: "",
  geolocalizacion_lat: "",
  fecha: "",
  hora: "",
  fechahora: "",
  carga_administrador: "",
  datos_empresa: {
    incidencia: "Alta",
    estado_empresa: "Operativa",
    observaciones: "Requiere mantenimiento en la fachada",
    fotos_empresa: [
      "https://th.bing.com/th/id/R.2951ff2fc8b0e8833f253e6e9de75ebe?rik=a0%2b8ZhQToZjF3w&pid=ImgRaw&r=0",
      "https://th.bing.com/th/id/R.2951ff2fc8b0e8833f253e6e9de75ebe?rik=a0%2b8ZhQToZjF3w&pid=ImgRaw&r=0",
      "https://th.bing.com/th/id/R.2951ff2fc8b0e8833f253e6e9de75ebe?rik=a0%2b8ZhQToZjF3w&pid=ImgRaw&r=0",
      "https://th.bing.com/th/id/R.2951ff2fc8b0e8833f253e6e9de75ebe?rik=a0%2b8ZhQToZjF3w&pid=ImgRaw&r=0",
    ],
    fecha_ult_modif: "2024-09-11",
  },
  datos_comuna: {
    chapa: "123",
    domicilio_real: "Av. Libertador 1234, Ciudad Autónoma de Buenos Aires",
    especie_arbol: "1",
    dap: "25 cm",
    altura: "15 m",
    danio: "3",
    referencia: "Vacia",
    geolocalizacion: "2131231,112321",
    incidencia: "2",
    estado: "3",
    corte_calle: "2",
    calle: "1",
    observaciones: "Bueno",
    fecha_ult_modif: "2024-09-10",
    fotos_comuna: [
      "https://th.bing.com/th/id/R.2951ff2fc8b0e8833f253e6e9de75ebe?rik=a0%2b8ZhQToZjF3w&pid=ImgRaw&r=0",
      "https://th.bing.com/th/id/R.2951ff2fc8b0e8833f253e6e9de75ebe?rik=a0%2b8ZhQToZjF3w&pid=ImgRaw&r=0",
      "https://th.bing.com/th/id/R.2951ff2fc8b0e8833f253e6e9de75ebe?rik=a0%2b8ZhQToZjF3w&pid=ImgRaw&r=0",
    ],
  },
  identificador: "",
};

const actualDate = moment();
const fechaDesde = moment(actualDate).subtract(60, "days").format(Global.FormatDate).toString();
const fechaHasta = actualDate.format(Global.FormatDate).toString();

const paginationInfo = {
  hasNextPage: null,
  hasPrevPage: null,
  limit: 50,
  nextPage: 2,
  page: 1,
  pagingCounter: 1,
  prevPage: null,
  totalDocs: 218,
  totalPages: 5,
};

const filterSuceso = {
  input: "",
  fechaDesde: fechaDesde,
  fechaHasta: fechaHasta,
  horaDesde: moment().set({ hour: 9, minute: 0, second: 0 }).format(Global.FormatTime),
  horaHasta: moment().set({ hour: 17, minute: 0, second: 0 }).format(Global.FormatTime),
  tipoCortesDeCalles: [],
  incidencia: [],
  estado: [],
};

export const sucesosSlice = createSlice({
  name: "suceso",
  initialState: {
    list: [],
    alone: initAlone,
    filters: filterSuceso,
    filtersCleared: false,
    pagination: paginationInfo,
  },
  reducers: {
    alone: (state, action) => {
      state.alone = {
        ...state.alone,
        ...action.payload,
      };
    },
    aloneComuna: (state, action) => {
      state.alone = {
        ...state.alone,
        datos_comuna: {
          ...state.alone.datos_comuna,
          ...action.payload,
        },
      };
    },
    list: (state, action) => {
      state.list = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    setfilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clean: (state) => {
      state.alone = initAlone;
      state.filters = filterSuceso;
      state.filtersCleared = true;
      state.pagination = paginationInfo;
    },
    resetFiltersCleared: (state) => {
      state.filtersCleared = false;
    },
  },
});

export const { alone, list, clean, setfilters, resetFiltersCleared, setPagination, aloneComuna } = sucesosSlice.actions;

export const getListSuceso = (state) => state.suceso.list;
export const getSuceso = (state) => state.suceso.alone;
export const getSucesoComuna = (state) => state.suceso.alone.datos_comuna;
export const getSucesoEmpresa = (state) => state.suceso.alone.datos_empresa;
export const getPagination = (state) => state.suceso.pagination;
export const getFilters = (state) => state.suceso?.filters || {};
export const getFiltersCleared = (state) => state.suceso.filtersCleared;
