import moment from "moment-timezone";
import { Global } from "../constants/global";

export const convertDateUtc = (date) => {
  const dateFormat = moment.tz(date, Global.TIMEZONE);
  return dateFormat.format(Global.FormatFullDate);
};

export const formatDate = (date) => {
  return moment.utc(date).format(Global.FormatDate);
};
