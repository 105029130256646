import { tiendasApi } from "../api";

export const authApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "login/user",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    getSelf: builder.mutation({
      query: (token) => ({
        url: "login/self-user",
        method: "GET",
        headers: {
          Authorization: token,
        },
      }),
    }),
  }),
});

export const { useLoginMutation, useGetSelfMutation, useLogOutMutation } = authApiSlice;
