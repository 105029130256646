import { configureStore } from "@reduxjs/toolkit";

import { setupListeners } from "@reduxjs/toolkit/query";
import { tiendasApi } from "./api/api";

import { authSlice } from "./slices/auth";
import { sidebarSlice } from "./slices/sidebar";
import { feedbackSlice } from "./slices/feedback";
import { sucesosSlice } from "./slices/sucesos";
import { tipoCortesSlice } from "./slices/tipoCortes";
import { tipoIncidenciaSlice } from "./slices/tipoIncidentes";
import { estadoSlice } from "./slices/estado";

export const store = configureStore({
  reducer: {
    [tiendasApi.reducerPath]: tiendasApi.reducer,
    auth: authSlice.reducer,
    sidebar: sidebarSlice.reducer,
    feedback: feedbackSlice.reducer,
    suceso: sucesosSlice.reducer,
    estado: estadoSlice.reducer,
    tipoIncidencia: tipoIncidenciaSlice.reducer,
    tipoCorte: tipoCortesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(tiendasApi.middleware),
});

setupListeners(store.dispatch);
