import { tiendasApi } from "../api";

export const sucesosApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getSucesos: builder.mutation({
      query: ({ page, cantRows, incidencia, estado, tipoCortesDeCalles, horaDesde, horaHasta, fechaDesde, fechaHasta, input }) => {
        const queryParams = new URLSearchParams({
          page,
          cantRows,
          tipoCortesDeCalles,
          fechaDesde,
          fechaHasta,
          horaDesde,
          horaHasta,
          estado,
          incidencia,
          input,
        }).toString();
        return {
          url: `/suceso?${queryParams}`,
          method: "GET",
        };
      },
    }),
    getSuceso: builder.mutation({
      query: (id) => ({
        url: "/suceso/" + id,
        method: "GET",
      }),
    }),
    createSuceso: builder.mutation({
      query: (body) => ({
        url: "/suceso",
        method: "POST",
        body: body,
      }),
    }),
    updateSuceso: builder.mutation({
      query: ({ id, data }) => ({
        url: `/suceso/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteSuceso: builder.mutation({
      query: (id) => ({
        url: `/suceso/${id}`,
        method: "DELETE",
      }),
    }),
    downloadTsv: builder.mutation({
      query: ({ incidencia, estado, tipoCortesDeCalles, horaDesde, horaHasta, fechaDesde, fechaHasta, input }) => {
        const queryParams = new URLSearchParams({
          tipoCortesDeCalles,
          fechaDesde,
          fechaHasta,
          horaDesde,
          horaHasta,
          estado,
          incidencia,
          input,
        }).toString();
        return {
          url: `/suceso/descargas-tsv?${queryParams}`,
          method: "GET",
        };
      },
    }),
    uploadImage: builder.mutation({
      query: (body) => ({
        url: "/suceso",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useGetSucesosMutation, useGetSucesoMutation, useCreateSucesoMutation, useUpdateSucesoMutation, useUploadImageMutation, useDeleteSucesoMutation, useDownloadTsvMutation } = sucesosApiSlice;
