import React from "react";
import { ButtonIcon } from "../atoms";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";

export default function Volver(props) {
  const navigate = useNavigate();

  const handleBack = () => {
    if (!props.to) {
      navigate(-1);
    } else {
      navigate(props.to);
    }
  };

  return (
    <ButtonIcon variant={props.variant ?? "outlined"} size={props.size ?? "medium"} name="navigation back" color={props.color ?? "neutral"} startIcon={props.icon ?? <ChevronLeftIcon />} noLink handleClick={handleBack}>
      {props.label}
    </ButtonIcon>
  );
}
